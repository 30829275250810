import { stringify } from 'query-string'
import { getBearerAuthorization } from '../../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/customers`

const listAll = async ({ search = '', rows = 10, p = 1 }) => {
  const querys = stringify({ search, rows, p })

  const list = await axios.get(`${url}/find-customers-emails?${querys}`, {
    headers: getBearerAuthorization()
  })

  return list
}

const listCustomersEmails = async (portalId, status) => {
  const querys = stringify({ status })

  const list = await axios.get(`${url}/find-customers-emails/${portalId}?${querys}`, {
    headers: getBearerAuthorization()
  })

  return list
}

const saveCustomerEmailJob = async (emailId) => {
  const saveEmailJob = await axios.post(
    `${url}/save-email-job`,
    { emailId },
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

const deleteEmailJob = async (emailId) => {
  const saveEmailJob = await axios.delete(
    `${url}/delete-email-job/${emailId}`,
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

const saveCustomerEmailDailyJob = async (emailId) => {
  const saveEmailJob = await axios.post(
    `${url}/save-email-daily-job`,
    { emailId },
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

const deleteEmailDailyJob = async (emailId) => {
  const saveEmailJob = await axios.delete(
    `${url}/delete-email-daily-job/${emailId}`,
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

const checkEmailJob = async (emailId) => {
  const saveEmailJob = await axios.get(
    `${url}/check-email-job?emailId=${emailId}`,
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

const saveCustomerDispute = async (customer, dispute) => {
  const saveEmailJob = await axios.post(
    `${url}/save-customer-dispute`,
    { customer, dispute },
    {
      headers: getBearerAuthorization()
    }
  )

  return saveEmailJob
}

export {
  listAll,
  listCustomersEmails,
  saveCustomerEmailJob,
  deleteEmailJob,
  checkEmailJob,
  saveCustomerEmailDailyJob,
  deleteEmailDailyJob,
  saveCustomerDispute
}
