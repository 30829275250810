import React, { Fragment } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import InputText from '../../component/select/input-text'

export default ({ docs, filters }) => {
  const { t } = useTranslation()
  const customerSelected = filters.filter(
    (filter) => filter.name === 'customer'
  )

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <InputText
            defaultValue={customerSelected[0].selected.label}
            label={t('customer')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            defaultValue={docs.billingNumber || docs.assignmentNumber}
            label={t('dispute-invoice-number')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <TextField
            defaultValue={docs.issuedCurrency}
            label={t('currency')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            defaultValue={docs.totalAmount}
            label={t('dispute-amount')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            defaultValue={docs.referenceNumber}
            label={t('dispute-reference')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            defaultValue={docs.issuedDate}
            label={t('dispute-issued-date')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            defaultValue={docs.dueDate}
            label={t('dispute-due-date')}
            disabled
            InputProps={{
              style: { fontSize: '12px' }
            }}
            InputLabelProps={{
              style: { fontSize: '12px' }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}
